import { styled } from "../../gatsby-theme-stitches/config"
import { Link } from "../Link"

export const PostsContainer = styled("div", {
  display: "grid",
  "@tablet": {
    gridTemplateColumns: "200px auto",
    alignItems: "flex-start",
  },
  "@desktop": {
    gridTemplateColumns: "350px auto",
    alignItems: "flex-start",
  },
})

export const PostsSection = styled("section", {
  maxWidth: 590,
  width: "100%",
})

export const ProjectsLink = styled(Link, {
  position: "sticky",
  display: "flex",
  alignItems: "center",
  svg: {
    marginRight: "$xs",
  },
  "@desktop": {
    top: 100,
  },
})

export const LifeEventPost = styled("div", {
  borderRadius: "$m",
  border: "1px solid $border",
  p: "$m",
  textAlign: "center",
})

export const SketchLabel = styled("div", {
  position: "absolute",
  right: 0,
  top: "80%",
  transform: "rotate(10deg) translateY(-50%)",
  background: "$border",
  px: "$s",
  py: "$xxs",
  borderRadius: "$m",
  color: "$text",
  border: "1px dashed $textSecondary",
})
