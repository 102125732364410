import React, { FunctionComponent, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import { Post } from "../@types/Post"
import {
  getSnippet,
  readingTime,
  toPlainText,
} from "../templates/Post/Post.utils"
import { Container } from "../components/Container.styles"
import { PostsList } from "../components/PostsList"

interface PostsPageProps {
  data: { allSanityPost: { nodes: Array<Post> } }
}

const PostsPage: FunctionComponent<PostsPageProps> = ({
  data: {
    allSanityPost: { nodes },
  },
}): JSX.Element => {
  const [posts] = useState(
    nodes.map((post) => {
      const plainTextBlocks = toPlainText(post?._rawBody || [])
      const timeToRead = readingTime(plainTextBlocks)
      const snippet = getSnippet(plainTextBlocks)

      return {
        title: post.title,
        timeToRead,
        snippet,
        link: post?._rawSlug?.current || "",
        date: post.publishedAt,
        isLifeEvent:
          post.categories.length > 0
            ? Boolean(
                post.categories.find((category) =>
                  category.title.includes("Life Event")
                )
              )
            : false,
        isSketch:
          post.categories.length > 0
            ? Boolean(
                post.categories.find((category) =>
                  category.title.includes("Sketch")
                )
              )
            : false,
      }
    })
  )

  return (
    <Layout>
      <SEO title="Blog" />
      <Container>
        <PostsList posts={posts} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        id
        title
        publishedAt(formatString: "MMMM Do, YYYY")
        _rawBody
        _rawAuthor
        _rawCategories
        _rawMainImage
        _rawSlug
        categories {
          title
        }
      }
    }
  }
`

export default PostsPage
