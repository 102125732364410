import { ArrowRightIcon, DoubleArrowLeftIcon } from "@radix-ui/react-icons"
import { Text } from "../Text/Text"
import { Stack } from "../Stack"
import { PageHeading } from "../PageHeading"
import { Link } from "../Link"
import React, { FC } from "react"
import {
  LifeEventPost,
  PostsContainer,
  PostsSection,
  SketchLabel,
} from "./PostsList.styles"
import { PostsListProps } from "./PostsList.types"
import { PreviousPageLink } from "../PreviousPageLink"

export const PostsList: FC<PostsListProps> = ({ posts }) => (
  <PostsContainer>
    <PreviousPageLink to="/">
      <em>Back to Projects</em>
    </PreviousPageLink>
    <PostsSection>
      <Stack space="l">
        <PageHeading title={"Coffee and Code"}>
          A collection of <em>thoughts and opinions</em>
          <br /> from over the years.
        </PageHeading>
        <Stack space="xl">
          {posts.map((post, index) => (
            <article key={post.title}>
              {post.isLifeEvent && (
                <LifeEventPost>
                  <Stack space="xs">
                    <Text as="h1" variant="body-1">
                      {post.title}
                    </Text>
                    <Text as="h2" variant="body-2">
                      <em>{post.date}</em>
                    </Text>
                  </Stack>
                </LifeEventPost>
              )}
              {!post.isLifeEvent && (
                <Stack space="xs" css={{ position: "relative" }}>
                  {post.isSketch && (
                    <SketchLabel>
                      <Text as="p" variant="body-3">
                        Sketch!
                      </Text>
                    </SketchLabel>
                  )}
                  <Text as="h1" variant="heading-2">
                    {post.title}
                  </Text>
                  <Text as="h2" variant="body-1">
                    <em>{post.date}</em> - <strong>{post.timeToRead}</strong>
                  </Text>
                  <Link
                    to={post.link}
                    css={{
                      display: "flex",
                      alignItems: "center",
                      svg: {
                        marginLeft: "$xs",
                      },
                    }}
                  >
                    <Text as="span" variant="subtitle-1">
                      Read more
                    </Text>
                    <ArrowRightIcon />
                  </Link>
                </Stack>
              )}
            </article>
          ))}
        </Stack>
      </Stack>
    </PostsSection>
  </PostsContainer>
)
