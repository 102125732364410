import { Text } from "../Text/Text"
import React, { FC } from "react"
import { Stack } from "../Stack"
import { PageHeadingProps } from "./PageHeading.types"
import { HorizontalRule } from "../HorizontalRule"

export const PageHeading: FC<PageHeadingProps> = ({ title, children }) => (
  <>
    <Text as="h1" variant="heading-1" css={{ mt: 0 }}>
      {title}
    </Text>
    <Stack space="l">
      <Text as="h2" variant="body-1">
        {children}
      </Text>
      <Stack space="xl">
        <HorizontalRule css={{ maxWidth: "600px" }} />
      </Stack>
    </Stack>
  </>
)
