import React, { FC, ReactNode } from "react"
import { ProjectsLink } from "../PostsList/PostsList.styles"
import { DoubleArrowLeftIcon } from "@radix-ui/react-icons"
import { Text } from "../Text/Text"
import { PreviousPageLinkProps } from "./PreviousPageLink.types"

export const PreviousPageLink: FC<PreviousPageLinkProps> = ({
  to,
  children,
}) => (
  <ProjectsLink to={to}>
    <DoubleArrowLeftIcon />
    <Text as={"p"} variant="body-1">
      {children}
    </Text>
  </ProjectsLink>
)
