import { RawContentBlock } from "../../@types/RawContentBlock"

// Why re-invent the wheel?

// http://www.craigabbott.co.uk/how-to-calculate-reading-time-like-medium
export const readingTime = (text: string): string => {
  const wordsPerMinute = 200
  const noOfWords = text.split(/\s/g).length
  const minutes = noOfWords / wordsPerMinute
  const readTime = Math.ceil(minutes)
  return `${readTime} minute read`
}

// https://www.sanity.io/docs/presenting-block-text#plain-text-serialization-ac67a867dd69
export const toPlainText = (blocks: Array<RawContentBlock>): string => {
  return blocks
    .map(block => {
      if (block._type !== "block" || !block.children) {
        return ""
      }
      return block.children.map(child => child.text).join("")
    })
    .join("\n\n")
}

// https://stackoverflow.com/questions/7170470/get-snippet-text-using-javascript
export const getSnippet = (text: string): string => {
  const textWithRudimentaryUrlRegex = text.replace(
    /(?:https?|ftp):\/\/[\n\S]+/g,
    ""
  )
  const textWithNewlinesReplaced = textWithRudimentaryUrlRegex.replace(
    /(\r\n|\n|\r)/gm,
    " "
  )
  if (textWithNewlinesReplaced.length < 100) return textWithNewlinesReplaced
  const spaceRegex = new RegExp("^.{100}\\w*")
  const matches = spaceRegex.exec(textWithNewlinesReplaced)
  if (matches) {
    return matches[0]
  }
  return ""
}
